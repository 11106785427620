import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { interval: Number }

  disconnect() {
    clearInterval(this.i)
  }

  connect() {
    let interval = this.intervalValue || 3000
    this.i = setInterval(() => {
      Turbo.visit(window.location, { frame: this.element.id })
    }, interval)
  }
}