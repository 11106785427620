import { Controller } from "@hotwired/stimulus"

const tdSelector = `.${CSS.escape('md:table-cell')} [data-sortable-target="td"]`
const rowSelector = `.${CSS.escape("md:table-row")}`

export default class extends Controller {
  static targets = ["head", "body", "row", "td"]

  connect() {
    this.directions = {}
    this.headTarget.querySelectorAll(`.${CSS.escape("md:table-cell")}`).forEach((th) => {
      if (th.querySelector("[data-sort=false]")) {
        th.removeAttribute("data-action")
        return
      }
      this.addInitialIcon(th)
      th.classList.add("cursor-pointer")
    });
  }

  arrowIcon() {
    return `
      <svg class="size-4 animate ease-linear duration-100 transition-all" id="chevron-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path>
      </svg>
    `;
  }

  buildIcon() {
    const container = document.createElement("div");
    container.classList.add("bg-gray-200", "hover:bg-gray-300", "rounded", "inline-block", "ml-2", "relative", "top-0.5")
    container.dataset.turboTemporary = true
    container.innerHTML = this.arrowIcon()
    return container;
  }

  addInitialIcon(th) {
    const iconNode = this.buildIcon()
    th.classList.add("relative", "selection-none")
    th.appendChild(iconNode)
  }

  rotateIcon(th) {
    this.headTarget.querySelectorAll(`.${CSS.escape("md:table-cell")}`).forEach((hth) => {
      if (hth === th) return
      hth.querySelector("svg")?.classList?.remove("rotate-180")
    })
    th.querySelector("svg").classList.toggle("rotate-180")
  }

  sortBy(event) {
    const th = event.currentTarget

    let dir = this.directions[th.innerText] || "asc"
    if (this.directions[th.innerText]) {
      dir = this.directions[th.innerText] === "asc" ? "desc" : "asc"
    }

    this.directions = { [th.innerText]: dir }
    this.rotateIcon(th)
    const tr = th.parentElement
    var idx = Array.from(tr.children).indexOf(th)
    this.sortTable(dir, idx)
  }

  sortTable(direction, idx) {
    this.lastSort = this.lastSort === "desc" ? "asc" : "desc";

    const rows = []
    this.bodyTarget.querySelectorAll(rowSelector).forEach((row) => {
      let text = row.querySelectorAll(tdSelector)[idx].innerText
      rows.push({
        row: row,
        sortBy: text,
      })
    })

    let numeric = !Number.isNaN(Number(rows[0].sortBy.replace(/\$/g, "")))
    let isDate = rows[0].sortBy.match(/(\d{1,4}(-|\/)\d{1,2}(-|\/)\d{1,4})/)
    if (Boolean(isDate)) {
      rows.sort((a, b) => {
        let dateA = new Date(a.sortBy.split(" ")[0])
        dateA = !isNaN(dateA) ? dateA : new Date()

        let dateB = new Date(b.sortBy.split(" ")[0])
        dateB = !isNaN(dateB) ? dateB : new Date()

        return dateA - dateB
      });
    } else {
      rows.sort((a, b) => {
        return a.sortBy.localeCompare(b.sortBy, 'en', { numeric })
      });
    }

    if (direction === "desc") {
      rows.reverse()
    }

    this.bodyTarget.innerHTML = ""
    rows.forEach((row) => {
      this.bodyTarget.appendChild(row.row)
    })
  }
}
