import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["list"]

  connect() {
    new Sortable(this.listTarget, {
      animation: 450,
      direction: "vertical",
      easing: "cubic-bezier(0, 1, 0, 1)",
      dragClass: "bg-gray-400",
      ghostClass: "opacity-0",
      onEnd: (e) => {
        const event = new CustomEvent('onSortEnd', { detail: e });
        this.element.dispatchEvent(event);
      }
    });
  }
}

