import { Controller } from "@hotwired/stimulus"
import { easepick, RangePlugin, LockPlugin, AmpPlugin } from "@easepick/bundle"

const formatDate = (d) => {
  const year = d.getFullYear()
  let month = d.getMonth() + 1
  let day = d.getDate()
  if (month < 10) {
    month = `0${month}`
  }
  if (day < 10) {
    day = `0${day}`
  }

  return `${year}-${month}-${day}`
}
export default class extends Controller {
  static targets = ["startDate", "endDate"]
  static values = {
    range: { type: Boolean, default: false },
    clear: { type: Boolean, default: false },
    startSelector: String,
    nextSelector: String,
    endSelector: String,
  }

  change = (e) => {
    if (this.rangeValue) {
      document.querySelector(this.startSelectorValue).value = formatDate(e.start)
      document.querySelector(this.endSelectorValue).value = formatDate(e.end)
      this.element.closest("form").requestSubmit()
    } else {
      this.element.value = formatDate(e.date)

      if (this.nextSelectorValue) {
        const nextFocus = document.querySelector(this.nextSelectorValue)
        nextFocus.focus()
        nextFocus.click()
      } else {
        if (this.element.dataset.submit !== "false") {
          this.element.closest("form").requestSubmit()
        }
      }
    }

  }

  focusNext = (e) => {
    if (this.nextSelectorValue) {
      e.preventDefault()
      const nextFocus = document.querySelector(this.nextSelectorValue)
      nextFocus.focus()
      nextFocus.showPicker();
    } else {
      this.element.closest("form").requestSubmit()
    }
  }

  calendarSvg = () => {
    return `<svg id="calendar" class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
      <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clip-rule="evenodd"/>
    </svg>`
  }

  addIcon = () => {
    this.element.parentElement.classList.add("relative")
    this.element.insertAdjacentHTML('afterend', `<div class='pointer-events-none absolute top-[34px] right-3'>${this.calendarSvg()}</div>`)
  }

  connect = () => {
    const isMobileDevice = window.matchMedia("only screen and (max-width: 760px)").matches
    const change = this.change
    const max = this.element.getAttribute("max")
    let plugins = [AmpPlugin]
    if (max) {
      plugins.push(LockPlugin)
    }
    if (this.rangeValue) {
      plugins.push(RangePlugin)
    }

    if (isMobileDevice) {
      this.element.dataset.action = "change->datepicker#focusNext"
    } else {
      this.addIcon()
      new easepick.create({
        element: this.element,
        css: [
          'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
          '/datepicker.css'
        ],
        plugins,
        firstDay: 0,
        AmpPlugin: {
          dropdown: {
            years: true,
            months: true,
            maxYear: (new Date()).getFullYear() + 1
          },
          resetButton: true,
          darkMode: false,
        },
        LockPlugin: {
          maxDate: max
        },
        setup(picker) {
          picker.on("select", (e) => {
            this.element.dispatchEvent(new Event('change'));
            change(e.detail)
          })
        }
      });

    }
  }
}
