const getCSRFToken = (): string => {
  return (
    document
      .querySelector("meta[name='csrf-token']")
      ?.getAttribute("content") ?? ""
  )
}

const baseRequest = async <T>({
  path,
  method,
  body = {},
  headers = {},
}: {
  path: string
  method: "post" | "get" | "patch" | "delete"
  body?: object | FormData
  headers?: object
}): Promise<T & { ok: boolean }> => {
  const csrf = getCSRFToken()
  const request: RequestInit = {
    method,
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrf,
      ...headers,
    },
  }

  if (method !== "get") {
    if (body instanceof FormData) {
      const contentType = "Content-Type"
      if (request.headers && contentType in request.headers) {
        // Will be set automatically to multipart byte separators
        // by the browser 
        delete request.headers[contentType]
      }
      request.body = body
    } else {
      request.body = JSON.stringify(body)
    }
  }

  const response = await fetch(path, request)
  const json = await response.json()
  return { ok: response.ok, ...json }
}

export const get = async <T>(path: string, headers = {}) => {
  return await baseRequest<T>({ path, method: "get", headers })
}

export const post = async <T>(path: string, body = {}, headers = {}) => {
  return await baseRequest<T>({ path, method: "post", body, headers })
}

export const patch = async <T>(path: string, body = {}, headers = {}) => {
  return await baseRequest<T>({ path, method: "patch", body, headers })
}

export const destroy = async <T>(path: string, body = {}, headers = {}) => {
  return await baseRequest<T>({ path, method: "delete", body, headers })
}
