import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  toggle = (e) => {
    e.preventDefault();
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.disabled) return;

      checkbox.checked = !checkbox.checked;
    });
  }
}